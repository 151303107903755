import { ref, watch, computed } from '@vue/composition-api';
import axios from '@axios';
import useAuth from '@/auth/useAuth';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n } = useI18nUtils();
function i18nT(str){
    return i18n(str)
  };
export default function useDocumentGroupList() {
    const refTaskListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        { key: 'checkbox', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk' },
        { key: 'name', label: i18nT(`Name`), sortable: true, thClass: 'pl-1', tdClass: 'pl-1' },
        { key: 'desription', label: i18nT(`Description`), sortable: true },
        { key: 'created', label: i18nT(`Created`), sortable: true },
        { key: 'count', label: i18nT(`Count`), sortable: true },
        {
            key: 'action',
            label: i18nT(`Actions`),
            thClass: 'tbl-actions',
            tdClass: 'sticky-column',
            stickyColumn: true
        },
    ];
    const perPage = ref(10);
    const totalDocumentgroups = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(true);
    const documentGroups = ref([]);

    const dataMeta = computed(() => {
        const localItemsCount = refTaskListTable.value ?
            refTaskListTable.value.localItems.length :
            0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalDocumentgroups.value,
        };
    });

    const refetchData = () => {
        refTaskListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, isSortDirDesc, sortBy], () => {
        refetchData();
    });

    const fetchDocumentgroups = (ctx, callback) => {
        const curWorkspace = useAuth.getCurWorkspace();

        const params = {
            page: currentPage.value,
            show_per_page: perPage.value,
            sort_by: sortBy.value,
            sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
            company_id: curWorkspace.Id,
        };

        axios
            .get(`documents/documentGroups`, {
                params,
            })
            .then(({ data }) => {
                documentGroups.value = data.data.document_groups;
                callback(data.data.document_groups);
                totalDocumentgroups.value = data.data.pagination.records_count;
            });
    };

    return {
        fetchDocumentgroups,
        tableColumns,
        perPage,
        currentPage,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refTaskListTable,
        totalDocumentgroups,
        documentGroups,
        refetchData,
    };
}