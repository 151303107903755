<template>
    <div>
        <b-card
            no-body
        >
            <div class="m-2">
                <div>
                    <b-row>
                        <b-col cols="12">
                            <h1 class="float-left">{{ i18nT(`Document groups`) }}</h1>
                            <b-button
                                variant="primary"
                                :to="{ name: 'create-document-group'}"
                                class="float-right"
                                v-if="iCan('documents', 'write')"
                            >
                                <feather-icon icon="PlusCircleIcon"/>
                                {{ i18nT(`Add new`) }}
                            </b-button>
                        </b-col>
                    </b-row>
                    <div class="mb-1">
                        <span class="text-muted">{{ i18nT(`Showing`) }} {{
                                dataMeta.from
                            }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{
                                dataMeta.of
                            }} {{ i18nT(`entries`) }}</span>
                    </div>
                </div>
            </div>

            <div>
                <div>
                    <div class="row mx-2">
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                            <v-select
                                v-model="perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block"
                            />
                            <b-dropdown
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                text="Actions"
                                size="md"
                                class="d-inline ml-1"
                                variant="outline-secondary"
                                v-if="selectedItems.length > 0 && iCan('documents', 'write')"
                            >
                                <b-dropdown-item @click="onBulkCopy(data)">
                                    <feather-icon icon="CopyIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onBulkDelete(data)">
                                    <feather-icon icon="TrashIcon" color="red"/>
                                    <span class="align-middle ml-50" style="color: red">{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                            <!-- <b-dropdown
                                size="md"
                                class="d-inline ml-1"
                                variant="outline-secondary"
                            >
                                <template v-slot:button-content>
                                    <feather-icon icon="ExternalLinkIcon" />&nbsp;&nbsp;{{i18nT(`Export`)}}
                                </template>
                                <b-dropdown-item @click="onExportExcel()">
                                    <feather-icon icon="CopyIcon" />
                                    <span class="align-middle ml-50">{{i18nT(`Excel`)}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onExportCsv()">
                                    <feather-icon icon="CopyIcon" />
                                    <span class="align-middle ml-50">{{i18nT(`CSV`)}}</span>
                                </b-dropdown-item>
                            </b-dropdown> -->
                        </div>
                        <!-- search input -->
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div role="group" class="input-group input-group-merge">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <feather-icon icon="SearchIcon"/>
                                            </div>
                                        </div>
                                        <b-form-input
                                            v-model="searchQuery"
                                            :placeholder="i18nT(`Search`)"
                                            type="text"
                                            class="d-inline-block"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <b-table
                        ref="refTaskListTable"
                        :items="fetchDocumentgroups"
                        responsive
                        :fields="computedColumns"
                        primary-key="id"
                        :sort-by.sync="sortBy"
                        show-empty
                        :empty-html="emptyHtml"
                        :sort-desc.sync="isSortDirDesc"
                        class="position-relative"
                        @refreshed="onTableRefresh"
                        v-model="currentItems"
                    >

                        <template v-slot:head(checkbox)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <b-form-checkbox
                                class="d-inline-block pl-0"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #head(action)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <div class="sk-column-control">
                                <b-dropdown
                                    no-caret
                                    variant="button"
                                    toggle-class="p-0"
                                    :right="true"
                                    class="pr-1 pl-1 d-flex sk-column-filter"
                                    v-if="betaFeature"
                                    v-b-tooltip = "i18nT(`Customize columns`)"
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="SettingsIcon"
                                            size="18"
                                            class="text-right"
                                        />
                                    </template>

                                    <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                        <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                    </div>
                                </b-dropdown>
                            </div>
                        </template>

                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                v-model="selectedItems"
                                :value="data.item.Id"
                            />
                        </template>

                        <template #cell(name)="data">
                            <b-link :to="{ name: 'edit-document-group', params: { id: data.item.Id } }">
                                {{ data.item.Label }}
                            </b-link>
                        </template>

                        <template #cell(desription)="data">
                            <p>
                                {{ data.item.Description }}
                            </p>
                        </template>

                        <template #cell(created)="data">
                            <p>
                                {{ data.item.CreatedAt | date }}
                            </p>
                        </template>

                        <template #cell(count)="data">
                            <p>
                                {{ data.item.TasksCount }}
                            </p>
                        </template>

                        <template #cell(action)="data">
                            <b-dropdown
                                variant="link"
                                toggle-class="p-0"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                            >

                                <template #button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                    />
                                </template>
                                <b-dropdown-item :to="{ name: 'edit-document-group', params: { id: data.item.Id } }">
                                    <feather-icon icon="EditIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onCopy(data)">
                                    <feather-icon icon="CopyIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item>
                                    <feather-icon icon="TrashIcon" color="red"/>
                                    <span class="align-middle ml-50" style="color: red">{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>

                </div>
                <!-- Disable grid view -->
                <b-tabs pills align="center" v-if="false">
                    <b-tab active>
                        <template #title>
                            <feather-icon icon="ListIcon" size="24" style="margin: 0;"/>
                        </template>
                    </b-tab>

                    <b-tab>
                        <template #title style="text-align: center">
                            <feather-icon icon="GridIcon" size="24" style="margin: 0;"/>
                        </template>
                        <div class="task-container">
                            <b-row class="task-item">
                                <b-col
                                    cols="3"
                                    v-for="documentGroup in documentGroups"
                                    :key="documentGroup.Id"
                                >
                                    <b-card
                                        style="border: 1px solid rgba(0, 0, 0, 0.07);"
                                    >
                                        <b-card-body class="p-0">
                                            <p>{{ documentGroup.TaskType }}</p>
                                            <h3 style="height: 50px;">{{ documentGroup.Label }}</h3>
                                            <p style="height: 45px; overflow: hidden;"
                                               v-html="documentGroup.Description"></p>
                                            <b-list-group class="list-group-messages" style="height: 140px">
                                                <b-list-group-item class="p-0 border-0 line-item"
                                                                   v-if="documentGroup.CreatedAt">
                                                    <feather-icon
                                                        icon="CalendarIcon"
                                                        size="18"
                                                        class="mr-75"
                                                    />
                                                    <span
                                                        class="align-text-bottom line-height-1">Created {{ documentGroup.CreatedAt | date }}</span>
                                                </b-list-group-item>
                                                <b-list-group-item class="p-0 border-0 line-item"
                                                                   v-if="documentGroup.UpdatedAt">
                                                    <feather-icon
                                                        icon="CheckCircleIcon"
                                                        size="18"
                                                        class="mr-75"
                                                    />
                                                    <span
                                                        class="align-text-bottom line-height-1">Last update {{ documentGroup.UpdatedAt | date }}</span>
                                                </b-list-group-item>
                                                <b-list-group-item class="p-0 border-0 line-item"
                                                                   v-if="documentGroup.TasksCount">
                                                    <feather-icon
                                                        icon="AlignLeftIcon"
                                                        size="18"
                                                        class="mr-75"
                                                    />
                                                    <span
                                                        class="align-text-bottom line-height-1">{{ documentGroup.TasksCount }} Tasks</span>
                                                </b-list-group-item>
                                            </b-list-group>
                                        </b-card-body>

                                        <b-card-footer class="pb-0 pt-1 pr-1 pl-1 ml-n2 mr-n2 text-top text-right">
                                            <b-dropdown
                                                variant="link"
                                                toggle-class="p-0"
                                                no-caret
                                                v-if="iCan('documents', 'write')"
                                            >

                                                <template #button-content>
                                                    <b-button
                                                    >
                                                        Actions
                                                    </b-button>
                                                </template>
                                                <b-dropdown-item>
                                                    <feather-icon icon="BookIcon"/>
                                                    <span class="align-middle ml-50">{{ i18nT(`View`) }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="onCopy(task)">
                                                    <feather-icon icon="CopyIcon"/>
                                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item>
                                                    <feather-icon icon="EditIcon"/>
                                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item>
                                                    <feather-icon icon="TrashIcon" color="red"/>
                                                    <span class="align-middle ml-50"
                                                          style="color: red">{{ i18nT(`Delete`) }}</span>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </b-card-footer>
                                    </b-card>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                </b-tabs>

                <div class="mx-1 mb-2">
                    <b-row>
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <b-form-checkbox
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                                All
                            </b-form-checkbox>
                            <span class="text-muted">{{ i18nT(`Showing`) }} {{
                                    dataMeta.from
                                }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{
                                    dataMeta.of
                                }} {{ i18nT(`entries`) }}</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalDocumentgroups"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    BCardFooter,
    BCardBody,
    BLink,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useDocumentGroupList from './useDocumentGroupList'
import {avatarText} from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import useAuth from "@/auth/useAuth";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTabs,
        BTab,
        BListGroup,
        BListGroupItem,
        BCardFooter,
        BCardBody,
        vSelect,
        BFormCheckbox,
        BLink,
        BFormGroup,
        BFormInput
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            pagination: {},
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            avalableFields: [
                {label: this.i18nT(`Description`), key: 'desription', visible: true},
                {label: this.i18nT(`Created`), key: 'created', visible: true},
          ],
            currentSoftware: useAuth.getCurrentSoftware(),
            controlVisible: true,
        }
    },
    created() {

        let storePreferences = useAuth.getColumnPreferences('documentGroups')
            if(storePreferences) {
               this.avalableFields = storePreferences
        }
    },
    methods: {
        getAvatarName(name) {
            if (name) {
                return name
                    .split(' ')
                    .map((namePart) => {
                        return namePart[0]
                    })
                    .join('')
                    .toUpperCase();
            } else {
                return ''
            }
        },

        onTableRefresh() {
            this.selectedItems = []
        },
        onCopy(element) {
            this.$http.post(
                `documents/copyDocumentGroup?document_group_id=${element.item.Id}`
            ).then(() => {
                this.refetchData()
            })
        },
        onDelete(task) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.$http.delete(
                        `documents/documentGroups?id=${task.item.Id}`
                    ).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The document has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                        this.refetchData()
                    })
                }
            })
        },
        onBulkCopy() {
            this.$http.post(
                `documents/copyDocumentGroup?document_group_id=${this.selectedItems.join(",")}`
            ).then(() => {
                this.selectedItems = []
                this.selectAllStatus = false
                this.refetchData()
            })
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.$http.delete(
                        `documents/documentGroups?id=${this.selectedItems.join(",")}`
                    ).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The task has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                        this.selectedItems = []
                        this.selectAllStatus = false
                        this.refetchData()
                    })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.Id)
            } else {
                this.selectedItems = []
            }
        },
        onExportExcel() {

        },
        onExportCsv() {

        }
    },
    setup() {
        const {
            fetchDocumentgroups,
            tableColumns,
            perPage,
            currentPage,
            totalDocumentgroups,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refTaskListTable,
            documentGroups,
            refetchData
        } = useDocumentGroupList()

        return {
            fetchDocumentgroups,
            tableColumns,
            perPage,
            currentPage,
            totalDocumentgroups,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refTaskListTable,
            avatarText,
            documentGroups,
            refetchData
        }
    },
    computed: {
        emptyHtml() {
          return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
              + this.i18nT(`Here, you can create and manage your document groups. No groups have been created yet.`) + "</h4>"
              +"<button class='btn btn-outline-secondary mt-2'  onclick='window.location.href=\"/" + this.currentSoftware+ "/create-document-group\"'>" + this.i18nT(`Create document group`) + "</button>"
        },
        computedColumns() {
            let col = [...this.tableColumns]

            for(let i = 0; i < this.avalableFields.length; i++) {
              if(!this.avalableFields[i].visible) {
                col = col.filter(c => c.key !== this.avalableFields[i].key)
              }
            }
            useAuth.setColumnPreferences('documentGroups', this.avalableFields);

            return col
      },
    }
}
</script>

<style lang="scss">
.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
